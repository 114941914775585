@import './partials/HomePage';
@import './partials/Navigation';
@import './partials/Footer';
@import './partials/Modal';



@font-face {
  font-family: 'Fellix-SemiBold';
  src: url('../Fonts/Fellix-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'Fellix-Bold';
  src: url('../Fonts/Fellix-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Mokoto';
  src: url('../Fonts/Mokoto.ttf') format('truetype');
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@700&family=Roboto+Condensed:wght@700&display=swap');


* {

   
    font-family: 'Inter', sans-serif;


  }
  
  body {

    font-family: 'Inter', sans-serif;

  }

  .privacy-policy {
padding: 1rem;
margin-top: 50px;
  }

  .disclaimer {
    padding: 1rem;
    margin-top: 50px;
  }
