
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@500;700&display=swap');


$color-primary: #15151c;  // Deep navy blue for a tech-forward feel
$color-secondary: #16213e; // Dark blue-grey for complementary elements
$color-accent: #1650d5;    // Cool blue accent for calls to action
$color-highlight: #e94560; // Bright red-pink for eye-catching highlights
$color-background: #f0f0f0; // Soft light grey for a neutral background
$color-white: #ffffff;     // Pure white for text or highlights
$font-stack: 'Poppins', 'Roboto', sans-serif; 

// Mixin for buttons
@mixin button-style($btn-color) {
  padding: 1rem 1rem;
  color: $color-white;
  background-color: $btn-color;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  font-family: 'Fellix-Bold';
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin: auto;
  align-items: center;
  justify-content: space-evenly;
  max-width: 270px;
  text-decoration: none;
  transition: all 0.3s ease;

  &:hover {
    background-color: darken($btn-color, 10%);
  }
}

// General body styles
body {
  font-family: $font-stack;
  color: $color-secondary;
  background-color: $color-background;
  line-height: 1.6;
 
}





// Homepage structure
.home {
  overflow-x: hidden !important; 
  .banner {
    position: relative;
    max-height: 500px; // Cap the banner height
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
      display: block;
    }

      // Add a dark overlay
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); // Dark overlay with 50% opacity
    z-index: 1; // Ensure it overlays the image
  }

    

    // Typing animation keyframes
    @keyframes typing {
      from { width: 0; }
      to { width: 100%; }
    }

    @keyframes blink-caret {
      from, to { border-color: transparent; }
      50% { border-color: $color-white; }
    }
  }

  .home-header {
    // Navigation styles (if any)
  }

  .home-main {
    .services {
      padding: 1rem 0 2rem;
      text-align: center;

      h1 {
        color: $color-primary;
        margin-bottom: 2rem;
        font-family: 'Fellix-Bold', sans-serif;

      }

      .security-categories {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin: 2rem 0;

        .card {
          flex-basis: calc(30% - 1rem);
          border-radius: 10px;
          margin: 0.5rem;
          background-size: cover;
          background-position: center;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 200px;
          color: $color-white;
          font-size: 1.2rem;
          transition: transform 0.3s ease;
          cursor: pointer;
          position: relative;

          &:hover {
            transform: translateY(-10px);
          }

          h3 {
            position: relative;
            text-align: center;
            z-index: 1;
            text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.8);
          }

          &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: linear-gradient(
              rgba(0, 0, 0, 0.5),
              rgba(0, 0, 0, 0.5)
            );
            border-radius: 10px;
          }
        }

        // Background images for cards
        .car-breakins {
          background-image: url('https://moonrock-images.s3.us-east-2.amazonaws.com/parking-garage.jpg');
        }
        .perimeter-detection {
          background-image: url('https://moonrock-images.s3.us-east-2.amazonaws.com/oil-pipeline.jpg');
        }
        .loitering-detection {
          background-image: url('https://moonrock-images.s3.us-east-2.amazonaws.com/streetcar-inside.jpg');
        }
      }

      .btn-contact {
        @include button-style($color-accent);
        margin-top: 2rem;
      }
    }

    .about {
      padding: 4rem 10px;
      background-color: $color-primary;
      color: $color-white;
      text-align: center;

      h2 {
        font-family: 'Fellix-Bold', sans-serif;

        margin-bottom: 1rem;
      }

      .outline {
        border: 2px solid $color-white;
        border-radius: 2px;
        margin: 2rem auto;
        max-width: 800px;
        padding: 2rem;

        p {
          font-family: 'Fellix-Bold';
          font-size: 1.4rem;
        }
      }

      .btn-more {
        @include button-style($color-accent);
        margin-top: 2rem;
        background-color: $color-white;
        color: $color-primary;

        &:hover {
          background-color: lighten($color-white, 10%);
        }
      }
    }

    .product-details {
      background: $color-background;
      color: $color-secondary;
      padding: 2rem;
      font-family: 'Fellix-Bold';

      .product-container {
        max-width: 800px;
        margin: auto;
        padding: 2rem;
        background: $color-white;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        border-radius: 8px;

        h1 {
          color: $color-primary;
          text-align: center;
          margin-bottom: 1rem;
          font-family: 'Fellix-Bold';
        }

        .product-section {
          margin-bottom: 2rem;

          h2 {
            border-bottom: 1px solid $color-secondary;
            padding-bottom: 0.5rem;
            margin-bottom: 1rem;
            font-family: 'Fellix-Bold', sans-serif;

          }

          ul {
            list-style-position: inside;
            padding-left: 0;

            li {
              margin-bottom: 0.5rem;
              font-family: 'Fellix-SemiBold';
            }
          }

          p {
            text-align: justify;
            line-height: 1.6;
            font-family: 'Fellix-SemiBold';
          }
        }
      }
    }

    .enquiry-form {
      background-color: #1650d5;
      padding: 4rem 10px;
      font-family: 'Fellix-Bold';

      .form-container {
        background: $color-white;
        padding: 2rem;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.7);
        max-width: 600px;
        margin: 0 auto;
        border: 2px solid $color-secondary;

        h2 {
          text-align: center;
          color: $color-primary;
          font-size: 2rem;
          margin-bottom: 1rem;
          font-family: 'Fellix-Bold';
        }

        p {
          text-align: center;
          margin-bottom: 2rem;
          font-family: 'Fellix-SemiBold';
        }

        .form-group {
          margin-bottom: 1.5rem;

          label {
            display: block;
            color: $color-secondary;
            margin-bottom: 0.5rem;
            font-size: 1.1rem;
            font-family: 'Fellix-Bold';
            text-align: left;
          }

          input[type="text"],
          input[type="email"],
          input[type="tel"] {
            width: 100%;
            padding: 10px;
            border-radius: 4px;
            border: 2px solid $color-secondary;
            font-size: 1rem;

            &:focus {
              border: 2px solid $color-accent;
              outline: none;
            }
          }

          textarea {
            width: 100%;
            padding: 10px;
            border-radius: 4px;
            border: 2px solid $color-secondary;
            height: 150px;
            font-size: 1rem;

            &:focus {
              border: 2px solid $color-accent;
              outline: none;
            }
          }
        }

        .submit-button {
          @include button-style($color-accent);
          width: 100%;
          font-size: 1.1rem;
          padding: 12px 0;
          transition: background-color 0.3s, transform 0.2s;
          font-family: 'Fellix-Bold';

          &:hover {
            transform: translateY(-2px);
            background-color: darken($color-accent, 10%);
          }
        }

        .sent {
          background-color: #4caf50 !important;
          color: white;
        }

        .feedback-message {
          margin-top: 1rem;
          color: #4caf50;
          text-align: center;
          font-family: 'Fellix-Bold';
        }
      }
    }
  }

  .home-footer {
    background-color: $color-primary;
    color: $color-white;
  }
}

// Media queries for responsiveness
@media (max-width: 768px) {
  .home-main {
    .services {
      .security-categories {
        .card {
          flex-basis: calc(100% - 1rem);
        }
      }
    }

    .about {
      padding: 2rem 1rem;

      .outline {
        max-width: 100%;
        padding: 1rem;

        p {
          font-size: 1.2rem;
        }
      }
    }

    .product-details {
      .product-container {
        padding: 1rem;
      }
    }

    .enquiry-form {
      padding: 2rem 1rem;

      .form-container {
        padding: 1rem;

        input[type="text"],
        input[type="email"],
        input[type="tel"],
        textarea {
          width: 100%;
        }
      }
    }

    .banner {
      max-height: 300px; // Adjusted banner height for mobile

      .banner-text {
        h1 {
          font-size: 1rem; // Adjust font size for mobile
        }

        .typed-text {
          font-size: 1rem; // Adjust font size for mobile
        }
      }
    }
  }
}

.banner-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: $color-white;
  font-size: 2rem;
  z-index: 2;


  h1 {
    font-family: 'Mokoto';
    margin-bottom: 0px ;
    letter-spacing: 3px;
  }
}

.typed-text {
  font-family: 'Fellix-Bold', sans-serif;

  font-size:1.8rem; // Adjust as needed
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  border-right: 0.1em solid $color-white;
  animation: typing 3s steps(30, end) forwards, blink-caret 0.75s step-end 3s;
  animation-fill-mode: forwards;
}


// Existing styles...

// Integration Section Styles
.integration {
  padding: 4rem 0;
  background-color: $color-white;
  color: $color-secondary;

  .integration-content {
    display: flex;
    align-items: center;
    max-width: 1200px;
    margin: auto;
    flex-wrap: wrap;

    .integration-text {
      flex: 1;
      padding: 1rem;

      h2 {
        font-family: 'Fellix-Bold', sans-serif;

        font-size: 2rem;
        margin-bottom: 1rem;
        color: $color-primary;
      }

      p {
        font-family: 'Fellix-SemiBold';
        font-size: 1.1rem;
        line-height: 1.6;
      }
    }

    .integration-image {
      flex: 1;
      padding: 1rem;
      text-align: center;

      img {
        max-width: 80%;
        height: auto;
      }
    }
  }
}

.solution-roadmap {
  padding: 2rem 10px;
  background-color: $color-background;
  color: $color-secondary;

  h2 {
    font-family: 'Fellix-Bold', sans-serif;
    font-size: 2.3rem;
    text-align: center;
    margin: 0rem auto;
    color: $color-primary;
    position: relative;
    display: inline-block; // Ensure h2 adjusts to its content width
    &::after {
      content: '';
      display: block;
      width: 50%;
      height: 4px;
      background: linear-gradient(to right, $color-accent, #153cff);
      margin: 0px auto 30px;
      border-radius: 2px;
    }
 
  }


  .roadmap-steps {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .step {
    width: 100%;
    margin-bottom: 0.4rem;

    .step-content {
      display: flex;
      align-items: center;
      max-width: 1200px;
      margin: 0 auto;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: -20px;
        left: 50%;
        transform: translateX(-50%);
        width: 2px;
        height: 20px;
        background-color: $color-accent;
      }

      &:first-child::before {
        display: none;
      }

      &.reverse {
        flex-direction: row-reverse;
      }

      .step-text,
      .step-image {
        flex: 1;
        padding: 1rem;
      }

      .step-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;

        h3 {
          font-family: 'Fellix-Bold';
          font-size: 2rem;
          margin-bottom: 1rem;
          color: $color-primary;
        }

        p {
          font-family: 'Fellix-SemiBold';
          font-size: 1.1rem;
          line-height: 1.6;
        }
      }

      .step-image {
        img {
          width: 100%;
          height: auto;
          border-radius: 10px;
          box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}

// Media Queries for Responsiveness
@media (max-width: 768px) {




  .solution-roadmap {
    .step {
      .step-content {
        flex-direction: column;

        &.reverse {
          flex-direction: column;
        }

        .step-text,
        .step-image {
          padding: 0.5rem;
          text-align: center;
        }

        .step-text {
          h3 {
            font-size: 1.5rem;
          }

          p {
            font-size: 1rem;
          }
        }
      }
    }
  }
}




// Media Queries for Responsiveness
@media (max-width: 768px) {
  .integration {
    .integration-content {
      flex-direction: column;

      .integration-text,
      .integration-image {
        flex: none;
        padding: 1rem;
      }

      .integration-image {
        img {
          max-width: 100%;
        }
      }
    }
  }

  .banner {
    margin-top: 90px;
    position: relative;
  }
  .banner-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: $color-white;
    z-index: 2;
    font-size: 1rem;

    h1 {
      h1 {
        margin-bottom: 0px ;
      }
    }
  
  }
  
  
  
    .typed-text {
      font-family: 'Fellix-Bold', sans-serif;

  
      font-size:1.4rem; // Adjust as needed
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      border-right: 0.1em solid $color-white;
      animation: typing 3s steps(30, end) forwards, blink-caret 0.75s step-end 3s;
      animation-fill-mode: forwards;
    }

}

.services {
  padding: 2rem 0;
  text-align: center;

  h1 {
    color: $color-primary;
    font-size: 1.8rem;
    margin-bottom: 2rem;
    font-family: 'Fellix-Bold', sans-serif;

  }

  .security-categories {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 2rem 0;

    .card {
      flex-basis: calc(30% - 1rem);
      border-radius: 10px;
      margin: 0.5rem;
      background-size: cover;
      background-position: center;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 200px;
      color: $color-white;
      font-size: 1.2rem;
      transition: transform 0.3s ease;
      cursor: pointer;
      position: relative;
      min-height: 170px;
      &:hover {
        transform: translateY(-10px);
      }

      h3 {
        position: relative;
        text-align: center;
        z-index: 1;
        text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.8);
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(
          rgba(0, 0, 0, 0.5),
          rgba(0, 0, 0, 0.5)
        );
        border-radius: 10px;
      }
    }
  }

  // Add styles for mobile
  @media (max-width: 768px) {
    margin-top: 0px;
   
    .security-categories {
      flex-direction: column;

      .card {
        flex-basis: calc(100% - 2rem); // Full width minus margin
        margin: 1rem 0; // Add vertical spacing between cards
        min-height: 170px;
      }
    }


   .banner-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: $color-white;
    font-size: 1rem;
    z-index: 2;

    

    h1 {
      font-family: 'Mokoto', sans-serif;
      font-size: 2rem; // Adjust as needed
      margin: 0;
    }

    .typed-text {
      font-family: 'Fellix-Bold', sans-serif;


      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      border-right: 0.1em solid $color-white;
      animation: typing 3s steps(30, end) forwards, blink-caret 0.75s step-end 3s;
      animation-fill-mode: forwards;
    }
  }

  
   
  }

  .btn-contact {
    @include button-style($color-accent);
    margin-top: 2rem;
  }
  
}



    // New Case Study Section Styles
    .case-study {
      padding: 4rem 0;
      background-color: $color-primary;
      color: $color-white;

      .case-study-content {
        display: flex;
        align-items: center;
        max-width: 1200px;
        margin: auto;
        flex-wrap: wrap;

        .case-study-image {
          flex: 1;
          padding: 1rem;
          text-align: center;

          img {
            max-width: 100%;
            height: auto;
            border-radius: 10px;
            box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
          }
        }

        .case-study-text {
          flex: 1;
          padding: 1rem;

          h2 {
            font-family: 'Fellix-Bold', sans-serif;

            font-size: 2rem;
            margin-bottom: 1rem;
            color: white;
          }

          p {
            font-family: 'Fellix-SemiBold';
            font-size: 1.1rem;
            line-height: 1.6;
          }
        }
      }
    }

    @media (max-width: 768px) {
      .case-study {
        .case-study-content {
          flex-direction: column;
  
          .case-study-image,
          .case-study-text {
            flex: none;
            padding: 1rem;
          }
        }
      }
    }


/* Fade-in animation */
.fade-in {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.fade-in.show {
  opacity: 1;
  transform: translateY(0);
}

/* Slide-in from left */
.slide-in-left {
  opacity: 0;
  transform: translateX(-100px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.slide-in-left.show {
  opacity: 1;
  transform: translateX(0);
}

/* Slide-in from right */
.slide-in-right {
  opacity: 0;
  transform: translateX(100px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.slide-in-right.show {
  opacity: 1;
  transform: translateX(0);
}
